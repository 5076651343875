import { useRef, useState } from 'react';
import logo from './logo.svg';
import ipqslogo from './ipqs.png'
import bg from './bg.png'
import { SiWhatsapp } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";

function App() {
  const kvah = useRef()
  const kwh = useRef()
  const perunitrate = useRef(9.6)
  const [unitgap, setUnitgap] = useState(0)
  // const [perunitrate, setPerunit] = useState(9.6)
  const [loss, setLoss] = useState(0)
  const [perunitrate_2, setPerunit_2] = useState(549)
  const [loss_2, setLoss_2] = useState(0)
  const [monthlyloss, setMonthlyloss] = useState(0)
  const [anualloss, setAnnloss] = useState(0)
  const [year_3, setYear_3] = useState(0)
  const [unitgap_2, setUnitgap_2] = useState(0)
  const kva = useRef()
  const kw = useRef()

  const calculate_pf =()=>{
    console.log(kvah.current.value, perunitrate.current.value, kwh.current.value, typeof(kwh.current.value), typeof(kvah.current.value))
    const unit_gap = kvah.current.value-kwh.current.value
    setUnitgap(unit_gap)
    const loss = unit_gap*perunitrate.current.value
    setLoss(loss)
    const unitgap_var_2 =kva.current.value-kw.current.value
    setUnitgap_2(unitgap_var_2)
    const unit_loss_2 = unitgap_var_2*perunitrate_2
    setLoss_2(unit_loss_2)

    const month_loss = loss+unit_loss_2
    setMonthlyloss(month_loss)
    // loss_2 = 
    const anual_loss = month_loss*12
    setAnnloss(anual_loss)
    const year_los_3 = anual_loss*3
    setYear_3(year_los_3)
  }



  return (
    <div className="h-screen w-full flex flex-wrap justify-center">
      <div className='lg:w-1/6 lg:h-screen w-full flex flex-wrap justify-center'>
        <div className=' lg:m-5'> <img src={ipqslogo} alt='logo'/> </div>
        <div className=' w-full ml-5 mr-5'>
          <table className=' text-xl w-full rounded-xl bg-gradient-to-tr from-slate-900 via-gray-600 to-slate-500 text-white'>
            <tr colSpan={2} className='text-center border-b p-2'><td><b>UNIT RATE</b></td></tr>
            <tr>
              <td>Commercial</td>
              <td>16</td>
            </tr>
            <tr>
              <td>Industrial</td>
              <td>10</td>
            </tr>
            <tr>
              <td>Public Service</td>
              <td>14</td>
            </tr>
          </table>
        </div>
      </div>
      <body className="lg:w-5/6 flex flex-wrap w-full items-center justify-center">
        <div className=' text-center text-3xl flex justify-center items-center font-bold'>Power Factor Calculator</div>
        <div className=' flex items-center justify-center w-full m-2  '>
          <table cellPadding={2} className=' ml-5 rounded-2xl text-center bg-gradient-to-tr from-slate-900 via-gray-600 to-slate-500 text-white'>
            <tbody>
              <tr className=''>
                <td className='font-bold' colSpan={4}>Consumer Number</td>
              </tr>
              <tr className='border'>
                <td className='font-bold'>KVAH</td>
                <td className=''><input type='text' placeholder='1. KVAH' ref={kvah} className='border-2 bg-white bg-opacity-10 rounded-lg p-2 w-32 bg-inherit'/></td>
                <td className='font-bold'>KVA(MD)</td>
                <td><input type='text' ref={kva} placeholder='3. KVA' className='border-2 bg-white bg-opacity-10 rounded-lg p-2 w-32 bg-inherit'/></td>
              </tr>
              <tr className='border'>
                <td className='font-bold'>KWH</td>
                <td><input type='text' ref={kwh} placeholder='2. KWH' className='border-2 bg-white bg-opacity-10 p-2 rounded-lg w-32 bg-inherit'/></td>
                <td className='font-bold'>KW(MD)</td>
                <td><input type='text' ref={kw} placeholder='4. KWn' className='border-2 bg-white bg-opacity-10 rounded-lg p-2 w-32 bg-inherit'/></td>
              </tr>
              <tr className='border'>
                <td className='font-bold'>Unit Gap</td>
                <td>{unitgap}</td>
                <td className='font-bold'>Unit Gap</td>
                <td>{unitgap_2}</td>
              </tr>
              <tr className='border'>
                <td className='font-bold'>Per Unit Rate</td>
                <td>
                  <select ref={perunitrate} onChange={console.log(perunitrate)} className='text-black text-2xl lg:text-xl'>
                    <option value={10}>10</option>
                    <option value={14}>14</option>
                    <option value={16}>16</option>
                  </select>
                  </td>
                <td className='font-bold'>Per Unit Rate</td>
                <td>{perunitrate_2}</td>
              </tr>
              <tr className=''>
                <td className='font-bold'>Loss</td>
                <td>{loss}</td>
                <td className='font-bold'> Loss</td>
                <td>{loss_2}</td>
              </tr>
              
            </tbody>
          </table>
        </div>
        <div className='w-full flex flex-wrap items-center justify-center'>
          <table cellPadding={10} className='rounded-xl text-center bg-gradient-to-tr from-slate-900 via-gray-600 to-slate-500 text-white'>
            <tr><td></td></tr>
                <tr className=''>
                  <td className='font-bold'>Current Month Total Loss</td>
                  <td>{monthlyloss}</td>
                  <td></td>
                </tr>
                <tr className='border'>
                  <td className='font-bold'>Annual Losses</td>
                  <td>{anualloss}</td>
                  <td></td>
                </tr>
                <tr className=''>
                  <td className='font-bold'>3 Yrs Loss</td>
                  <td>{year_3}</td>
                  <td></td>
                </tr>
          </table>
          <div className=' w-full flex items-center justify-center flex-wrap'>
            <input type='submit' className=' border-2 bg-slate-400 m-4 hover:cursor-pointer hover:bg-black hover:text-white text-2xl font-bold p-4 rounded-md' onClick={calculate_pf} value={"Calculate"}/>
          </div>
        </div>
        <div className=' w-full flex text-2xl font-bold flex-wrap justify-center items-center'>
            <span className=' block'>Contact Us : </span>
            <a href='https://wa.me/+918956535712' className='block p-3'><span > Whatsapp  <SiWhatsapp className='inline text-2xl text-green-600'/></span></a>
            <a className='block p-3'><span > Phone:   <FaPhone  className='inline text-2xl text-black'/> +91 8956536712 <FaPhone  className='inline text-2xl text-black'/> +91 8956536701 </span></a>
        </div>
        <div className=' p-10 flex items-center justify-center flex-wrap'>
          <span className=' lg:hidden text-3xl font-bold underline m-4'>Zoom to View</span>
          <img src={bg} className='w-full'/>
        </div>
        {/* <table>
        </table> */}
      </body>
      
      
    </div>
  );
}

export default App;
